import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IBill } from '../interfaces';


const base64 = require('base-64');

const baseURL = 'https://api.railz.ai/v2';
const connUUID = 'CON-27e48877-ba4c-42da-8b4a-0f36b1ad9b09';
const RAILZ_SANDBOX_API_CLIENT_ID = 'SB_PROD_537A5951E33D46E198A96408E4699081';
const RAILZ_SANDBOX_API_SECRET = 'sb_prod_Ze4eaul2Xjx6E5WUmQ/NRzqb2q+Xw0OhFAnzphYFBkI6aAsh';
let maxID = 0;
let billAtMaxID: IBill | null = null;

let token = '';

export const getAccessToken = async () => {
    const encoded = base64.encode(`SB_PROD_537A5951E33D46E198A96408E4699081:sb_prod_Ze4eaul2Xjx6E5WUmQ/NRzqb2q+Xw0OhFAnzphYFBkI6aAsh`);
    const response = await axios.get(`https://auth.railz.ai/getAccess`, 
        {
            headers: {
              'accept': 'application/json',
              'authorization': 'Basic ' + encoded
            }
        });
    console.log(response.data);
    token = (response.data.access_token);
    return response.data.access_token;
  };

export const createJournalEntry = async (vendorRef, discountAmount) => {
    console.log("discountAmount in createJournalEntry ", discountAmount);
    const options = {
        method: 'POST',
        url: 'https://api.railz.ai/v2/accounting/journalEntries',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: 'Bearer ' + token
        },
        data: {
          data: {
            currency: 'USD',
            memo: 'Buyer-initiated early payment offer through dynamic discounting for Invoice #56789, using the Formula platform. A $[discount amount] discount was automatically applied, reducing the payment from [currnacy][original total] to [currency][new total], based on early payment made on [payment date]',
            lines: [
              {accountRef: {id: '93'}, entityRef: {type: 'vendor', id: vendorRef}, credit: discountAmount},
              {accountRef: {id: '33'}, entityRef: {type: 'vendor', id: vendorRef}, debit: discountAmount}
            ]
          },
          connectionUuid: 'CON-27e48877-ba4c-42da-8b4a-0f36b1ad9b09'
        }
      };

      axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
}

export const getLastJournalEntry = async (discountAmount) => {
    let journalEntryID = '';
    console.log("Discount Amount in Last Journal Entry", discountAmount);
    const options = {
      method: 'GET',
      url: 'https://api.railz.ai/v2/accounting/journalEntries',
      params: {
        connectionUuid: 'CON-27e48877-ba4c-42da-8b4a-0f36b1ad9b09',
        totalDebit: discountAmount.toString()
      },
      headers: {
        accept: 'application/json',
        authorization: 'Bearer ' + token
      }
    };
    
    await axios
      .request(options)
      .then(async (response) => {
        console.log("Last Journal Entry Response",response);
        console.log("Last Journal Entry Data",response.data);
        console.log("Last Journal Entry Data 0",response.data.data[0]);
        console.log("Last Journal Entry Data 0 ID",response.data.data[0].id);
        journalEntryID = response.data.data[0].id;
        return new Promise(resolve => resolve(journalEntryID));
      })
      .catch(function (error) {
        console.error(error);
      });
}

export const createBillPayments = async (bill_id, credit_note_id, vendorRef, amount, discountAmount) => {
    console.log("Final Bill ID", bill_id);
    console.log("Final Credit Note ID", credit_note_id);
    console.log("Final Vendor Ref", vendorRef);
    console.log("Final Amount", amount);
    console.log("Final Discount Amount", discountAmount);
    const options = {
        method: 'POST',
        url: 'https://api.railz.ai/v2/accounting/bills/payments',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: 'Bearer ' + token
        },
        data: {
          data: {
            vendorRef: {id: vendorRef},
            accountRef: {id: '41'},
            paymentMethodRef: {id: 'CreditCard', name: 'CreditCard', type: 'creditCard'},
            memo: 'Bill',
            totalAmount: (parseFloat(amount)+ parseFloat(discountAmount)),
            currency: 'USD',
            lines: [{amount: parseFloat(amount), links: [{id: bill_id, type: 'bill'}]},
                    {amount: parseFloat(discountAmount), links: [{id: credit_note_id, type: 'other'}]}
            ],
          }, // Add this comma
          connectionUuid: 'CON-27e48877-ba4c-42da-8b4a-0f36b1ad9b09'
        }
      };
      
      axios
        .request(options)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
}

export const createBillPaymentsWithCredit = async (bill_id,vendorRef, amount) => {
    const options = {
        method: 'POST',
        url: 'https://api.railz.ai/v2/accounting/bills/payments',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: 'Bearer ' + token
        },
        data: {
          data: {
            vendorRef: {id: vendorRef},
            accountRef: {id: '41'},
            paymentMethodRef: {id: 'CreditCard', name: 'CreditCard', type: 'creditCard'},
            memo: 'Bill',
            totalAmount: parseFloat(amount),
            currency: 'USD',
            lines: [{amount: parseFloat(amount), links: [{id: bill_id, type: 'bill'}]}]
          },
          connectionUuid: 'CON-27e48877-ba4c-42da-8b4a-0f36b1ad9b09'
        }
      };
      
      axios
        .request(options)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
}

export const getBillsFromRailz = async () => {
    const response = await axios.get(`https://api.railz.ai/v2/accounting/bills?connectionUuid=${connUUID}`, 
        {
            headers: {
              'accept': 'application/json',
              'authorization': 'Bearer ' + token
            }
        });

    console.log("Before parse railzBills",response.data.data);
    const mappedBills = mapERPBillstoDBBills(response.data.data);
    console.log("railzBills",mappedBills);
    mappedBills.map((bill) => {
        if(maxID <= Number(bill.source_bill_id)){
          maxID = Number(bill.source_bill_id);
          billAtMaxID = bill;
        }
      });
      console.log("Max ID is", maxID);
      console.log("Max ID Bill is", billAtMaxID);
      return billAtMaxID;
};

export const queueRailzDataSync = async () => {
    const options = {
      method: 'POST',
      url: 'https://api.railz.ai/v2/data/sync',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: 'Bearer ' + token
      },
      data: {
        fullSync: true,
        connectionUuid: 'CON-27e48877-ba4c-42da-8b4a-0f36b1ad9b09',
        dataTypes: [
          "billPayments",
          "billCreditNotes",
          "bills",
          'journalEntries'
        ]
      }
    };
    
    axios
      .request(options)
      .then(function (response) {
        console.log("Queue Railz Data Sync", response.data);
        return new Promise(resolve => setTimeout(resolve, 30000)); // Wait for 30 seconds
      })
      .catch(function (error) {
        console.error(error);
      });
  }

const mapERPBillstoDBBills = (bills: any) => {
    return bills.map((bill: any) => {
      let mappedBill : IBill = {
        source_bill_id: bill.id,
        source_invoice_number: bill.vendorInvoiceNumber ? bill.vendorInvoiceNumber : null,
        vendor_name: (bill.vendorRef && bill.vendorRef.name) ? bill.vendorRef.name : null,
        source_vendor_id: (bill.vendorRef && bill.vendorRef.id) ? bill.vendorRef.id : null,
        source_created_date: bill.postedDate ? bill.postedDate : null,
        due_date: bill.dueDate ? bill.dueDate : null,
        currency: bill.currency ? bill.currency : null,
        status: bill.status ? bill.status : null,
        total_amount: bill.totalAmount ? bill.totalAmount : null,
        amount_due: bill.amountDue ? bill.amountDue : null,
        sub_total: bill.subTotal ? bill.subTotal : null,
        tax_amount: bill.taxAmount ? bill.taxAmount : null,
        currency_rate: bill.currencyRate ? bill.currencyRate : null,
        source_modified_date: bill.sourceModifiedDate ? bill.sourceModifiedDate : null,
        saved_amount: bill.savedAmount ? bill.savedAmount : null,
        max_savings: bill.maxSavings ? bill.maxSavings : null,
        payment_status: bill.paymentStatus ? bill.paymentStatus : null,
        days_left: bill.daysLeft ? bill.daysLeft : null,
        new_due_date: bill.newDueDate ? bill.newDueDate : null,
        new_total_amount: bill.newTotalAmount ? bill.newTotalAmount : null,
        new_currency_rate: bill.newCurrencyRate ? bill.newCurrencyRate : null,
      }
      return mappedBill;
    });
  };
  export const testSomething = async () => {
    const options = {
        method: 'POST',
        url: 'https://api.railz.ai/v2/accounting/bills/payments',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0ZWFtSWQiOjEwOTAsInRlYW1VdWlkIjoiVE0tMmI4MDhjYmMtZDhhMi00ZjllLWFjZTYtM2QxM2Y1NGRhOWZkIiwiY3VzdG9tZXJTZWNyZXRJZGVudGlmaWVyIjoiU0JfUFJPRF81MzdBNTk1MUUzM0Q0NkUxOThBOTY0MDhFNDY5OTA4MSIsInNhbmRib3giOnRydWUsIm5hbWUiOiJhY2Nlc3MiLCJiZXRhIjpmYWxzZSwiaWF0IjoxNzI2MjI2OTg3LCJleHAiOjE3MjYyMzA1ODcsImF1ZCI6ImFwaSJ9.D1NffS7Ven4nqsqUeD8dw5OSivvMAnem2OcOWvXn864'
        },
        data: {
          data: {
            vendorRef: {id: '30'},
            accountRef: {id: '41'},
            paymentMethodRef: {id: 'CreditCard', name: 'CreditCard', type: 'creditCard'},
            memo: 'Bill',
            totalAmount: 765871,
            currency: 'USD',
            lines: [
              {amount: 751242.86, links: [{id: '312', type: 'bill'}]},
              {amount: 14628.14, links: [{type: 'other', id: '313'}]}
            ]
          },
          connectionUuid: 'CON-27e48877-ba4c-42da-8b4a-0f36b1ad9b09'
        }
      };
      
      axios
        .request(options)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
  }

  export const createBillCredit = async (vendorId, amount, description: "Early Payment Discount (Reduction of COGS)") => {
    const options = {
      method: 'POST',
      url: 'https://api.railz.ai/v2/accounting/bills/creditNotes',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: 'Bearer ' + token
      },
      data: {
        data: {
          vendorRef: { id: vendorId },
          lines: [
            {
              billableStatus: 'billable',
              accountRef: { id: '93' },
              description: description,
              unitAmount: amount,
              quantity: 1
            }
          ],
          memo: 'Early Payment Discount (Reduction of COGS)',
          currency: 'USD'
        },
        connectionUuid: 'CON-27e48877-ba4c-42da-8b4a-0f36b1ad9b09'
      }
    };

    try {
      const response = await axios.request(options);
      console.log("Credit note created:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error creating credit note:", error);
      throw error;
    }
  };

  export const exampleThatWorks = async (bill_id,vendorRef, amount, discountAmount) => {
    const options = {
        method: 'POST',
        url: 'https://api.railz.ai/v2/accounting/bills/payments',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: 'Bearer ' + token
        },
        data: {
          data: {
            vendorRef: {id: vendorRef},
            accountRef: {id: '41'},
            paymentMethodRef: {id: 'CreditCard', name: 'CreditCard', type: 'creditCard'},
            memo: 'Early Payment Discount',
            totalAmount: Number(parseFloat(amount).toFixed(2))-Number(parseFloat(discountAmount).toFixed(2)),
            currency: 'USD',
            lines: [
              {amount: Number(parseFloat(amount).toFixed(2)), links: [{type: 'bill', id: bill_id}]},
              {amount: Number(parseFloat(discountAmount).toFixed(2)), links: [{id: (Number(bill_id)+1).toString(), type: 'creditNote'}]}
            ]
          },
          connectionUuid: 'CON-27e48877-ba4c-42da-8b4a-0f36b1ad9b09'
        }
      };
      
      axios
        .request(options)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
  }