import { Databases, ID, Query, Models } from '@refinedev/appwrite';
import { appwriteClient } from '../appwriteClient.ts';
import { IBill } from '../interfaces';
const databases = new Databases(appwriteClient);
let maxID = 0;
let billAtMaxID: Models.Document | null = null;

const latestBillID = async () => {
    const bills = await databases.listDocuments(
        'formula-test',
        'bills'
    );

    const existingBills = bills.documents;
    console.log("Existing Bills are", existingBills);
    existingBills.map((bill) => {
      if(maxID <= Number(bill.source_bill_id)){
        maxID = Number(bill.source_bill_id);
        billAtMaxID = bill;
      }
    });

    console.log("Max ID Bill is", billAtMaxID);
    console.log("Max ID is", maxID);

    return maxID;
};

const mapDocumentToBill = (doc: Models.Document): IBill => ({
    source_bill_id: doc.source_bill_id,
    source_invoice_number: doc.source_invoice_number,
    vendor_name: doc.vendor_name,
    source_vendor_id: doc.source_vendor_id,
    source_created_date: doc.source_created_date,
    due_date: doc.due_date,
    currency: doc.currency,
    status: doc.status,
    total_amount: doc.total_amount,
    amount_due: doc.amount_due,
    sub_total: doc.sub_total,
    tax_amount: doc.tax_amount,
    currency_rate: doc.currency_rate,
    saved_amount: doc.saved_amount,
    max_savings: doc.max_savings,
    payment_status: doc.payment_status,
    days_left: doc.days_left,
    new_due_date: doc.new_due_date,
    new_total_amount: doc.new_total_amount,
    new_currency_rate: doc.new_currency_rate,
    source_modified_date: doc.source_modified_date,
  });

// export const getBills = async () => {
//     const bills = await databases.listDocuments(
//         process.env.REACT_APP_DATABASE_ID as string,
//         process.env.REACT_APP_BILLS_COLLECTION_ID as string
//     );
//     return bills;
// };

export const getBillById = async () => {
    const latestId = await latestBillID();
    const bills = await databases.listDocuments(
        'formula-test',
        'bills',
        [Query.equal('source_bill_id', latestId.toString())]
    );
    if (bills.documents.length === 0) {
        throw new Error('Bill not found');
    }
    const bill = bills.documents[0];
    console.log("Bill is", bill);
    return mapDocumentToBill(bill);
};
