import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Box, Typography, Button, Stepper, Step, StepLabel, Fade, Stack, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import LaunchIcon from '@mui/icons-material/Launch';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import EarlierPaymentBcg from './components/bcg_images/earlier_payment_bcg.svg';
import { getBillById } from './api/billsAPI.ts';
import { DateTime, Formats } from 'luxon';
import axios from 'axios';
import { getAccessToken, TestSomething } from './api/railzAPI.ts';
import { createJournalEntry } from './api/railzAPI.ts';
import { createBillPayments } from './api/railzAPI.ts';
import { getBillsFromRailz } from './api/railzAPI.ts';
import { getLastJournalEntry } from './api/railzAPI.ts';
import { queueRailzDataSync } from './api/railzAPI.ts';
import { createBillCredit } from './api/railzAPI.ts';
import { exampleThatWorks } from './api/railzAPI.ts';



function getRandomNumber(min, max) {
    return Math.round(Math.random() * (max - min) + min);
}
function WebhookListener() {
  const [webhookData, setWebhookData] = useState(null);

  useEffect(() => {
    const eventSource = new EventSource('https://embedded-component.formula.inc/webhook');

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'webhook') {
        setWebhookData(data.data);
      }
    };

    return () => {
      eventSource.close();
    };
  }, []);

  if (!webhookData) return <div>Waiting for webhook...</div>;

  return (
    <div>
      <h2>Webhook Received!</h2>
      <pre>{JSON.stringify(webhookData, null, 2)}</pre>
    </div>
  );
}

/**
 * Mimic fetch with abort controller https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
 * ⚠️ No IE11 support
 */
function fakeFetch(date, { signal }) {
    return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
            const daysInMonth = dayjs(date).daysInMonth();
            const daysToHighlight = [1, 2, 3].map(() => getRandomNumber(1, daysInMonth));
  
            resolve({ daysToHighlight });
        }, 500);
  
        signal.onabort = () => {
            clearTimeout(timeout);
            reject(new DOMException('aborted', 'AbortError'));
        };
    });
}

const initialValue = dayjs('2024-10-01');

const BlueText = styled(Box)(({ theme, isSelected }) => ({
    color: isSelected ? 'white' : '#1E61FE',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: '0.7rem',
}));

const StyledDay = styled(PickersDay)(({ theme, isSelected }) => ({
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: 'rgba(30, 97, 254, 0.1)',
  },
  ...(isSelected && {
    backgroundColor: '#050C24 !important',
    color: 'white !important',
  }),
}));

function ServerDay(props) {
    const { highlightedDays = {}, day, outsideCurrentMonth, startDate, endDate, ...other } = props;
    const percentage = highlightedDays[day.date()];
    const isWithinRange = (!startDate || day.isSame(dayjs(), 'day') || day.isAfter(dayjs(), 'day')) && 
                            (!endDate || day.isSame(endDate, 'day') || day.isBefore(endDate, 'day'));
    const isSelected = !props.outsideCurrentMonth && props.selected;

    return (
        <Box sx={{ position: 'relative', width: '100%', height: '100%'}}>
            <StyledDay 
                {...other} 
                outsideCurrentMonth={outsideCurrentMonth} 
                day={day} 
                isSelected={isSelected}
                disabled={!isWithinRange}
            />
            {percentage !== undefined && isWithinRange && (
                <BlueText isSelected={isSelected} sx={{marginBottom: '5px', marginLeft: '5px', fontWeight: 'bold'}}>
                    {percentage.toFixed(2)}%
                </BlueText>
            )}
        </Box>
    );
}

function App() {
    // useEffect(() => {
    //   createBillCredit()
    //     .then(() => console.log("Bill Credit worked"))
    //     .catch(error => console.error("Error in token retrieval or journal entry creation:", error));
    // }, []);
    return (
        <div className="App">
            <MyComponent maxPercent={3} minPercent={1}/>
            {/* <WebhookListener /> */}
        </div>
    );
}

const MyComponent = ({ maxPercent = 4, minPercent = 1 }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [creditNoteId, setCreditNoteId] = useState(null);
  const [webhookStatus, setWebhookStatus] = useState(null);

  useEffect(() => {
    const eventSource = new EventSource('/events');
    
    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'creditNoteCreated') {
        setWebhookStatus('Credit note creation confirmed');
        setCreditNoteId(data.ids[0]);  // Assuming only one credit note is created at a time
      } else if (data.type === 'creditNoteCreationFailed') {
        setWebhookStatus('Credit note creation failed');
      }
    };

    return () => {
      eventSource.close();
    };
  }, []);

  const handleCreateCreditNote = async () => {
    setIsLoading(true);
    setWebhookStatus(null);
    try {
      await createBillCredit('56', 1234, 'Early Payment Discount');
      console.log("Credit note creation initiated, waiting for webhook confirmation...");
    } catch (error) {
      console.error("Error initiating credit note creation:", error);
      setWebhookStatus('Failed to initiate credit note creation');
    } finally {
      setIsLoading(false);
    }
  };

  const [bill, setBill] = useState(null);
  const [journalEntryID, setJournalEntryID] = useState(null);
  useEffect(() => {
    getAccessToken().then(() => {
      getBillsFromRailz().then(setBill);
    }).catch(error => console.error("Error in token retrieval or journal entry creation:", error));
  }, []);

  const [activeStep, setActiveStep] = useState(0);
  const [highlightedDays, setHighlightedDays] = useState({});
  const [startDate, setStartDate] = useState(dayjs('2024-09-01'));
  const [endDate, setEndDate] = useState(dayjs('2024-10-06'));
  const [selectedDate, setSelectedDate] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [discountedAmount, setDiscountedAmount] = useState(null);


  const fetchHighlightedDays = (date) => {
      const newHighlightedDays = {};
      const totalDays = endDate.diff(startDate, 'day') + 1;
      const percentStep = (maxPercent - minPercent) / (totalDays - 1);

      for (let i = 0; i < totalDays; i++) {
          const currentDate = startDate.add(i, 'day');
          const percentage = maxPercent - (percentStep * i);
          newHighlightedDays[currentDate.date()] = percentage;
      }

      setHighlightedDays(newHighlightedDays);
      setIsLoading(false);
  };

  useEffect(() => {
    setDiscountedAmount(1);
    setDiscount(1);
      fetchHighlightedDays(startDate);
  }, []);

  const handleMonthChange = (date) => {
      setIsLoading(true);
      setHighlightedDays({});
      fetchHighlightedDays(date);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    const percentage = highlightedDays[date.date()];
    console.log("Percentage: ", percentage);
    
    const newDiscount = percentage ? parseFloat(percentage.toFixed(2)) : null;
    setDiscount(newDiscount);
    
    const newDiscountedAmount = newDiscount ? (bill.total_amount * newDiscount / 100).toFixed(2) : null;
    setDiscountedAmount(newDiscountedAmount);
    
    console.log("Bill Total Amount: ", bill.total_amount);
    console.log("Discount: ", newDiscount);
    console.log("Discounted Amount: ", newDiscountedAmount);
  };

  const handleNextStep = async () => {
    if (activeStep === 1) {
      await createBillCredit('56', parseFloat(discountedAmount), 'Early Payment Discount')
      .then(() => {
        console.log("Credit Note created successfully");
      })
      .catch(error => {
        console.error("Error creating Credit Note:", error);
      });
      await new Promise(resolve => setTimeout(resolve, 15000));
      await queueRailzDataSync()
      .then(() => {
        console.log("Last Journal Entry fetched successfully");
      })
      .catch(error => {
        console.error("Error fetching last journal entry:", error);
      });
      await new Promise(resolve => setTimeout(resolve, 20000));
      // let journalRecentEntryID = await getLastJournalEntry(parseFloat((bill.total_amount * discount / 100).toFixed(2)))
      // .then(async (journalEntryID) => {
      //   console.log("Last Journal Entry fetched successfully with ID: ", journalEntryID);
      // })
      // .catch(error => {
      //   console.error("Error fetching last journal entry:", error);
      // });
      // console.log("Recent Last Journal Entry fetched successfully with ID: ", journalRecentEntryID);

      await exampleThatWorks(bill.source_bill_id, bill.source_vendor_id  ? bill.source_vendor_id : '56' , bill.total_amount, discountedAmount)
        .then(() => {
          console.log("Bill payments created successfully");
          // You can add any additional logic here after successful creation
        })
        .catch(error => {
          console.error("Error creating bill payments:", error);
          // Handle the error appropriately
        });


    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const steps = ['Select Date & Discount', 'Confirm New Deal Terms', 'Thank You'];

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                value={selectedDate}
                onChange={handleDateSelect}
                loading={isLoading}
                onMonthChange={handleMonthChange}
                renderLoading={() => <DayCalendarSkeleton />}
                slots={{
                  day: ServerDay,
                }}
                slotProps={{
                  day: {
                    highlightedDays,
                    startDate,
                    endDate,
                  },
                }}
                shouldDisableDate={(day) => {
                  const isWithinRange = (!startDate || day.isSame(dayjs(), 'day') || day.isAfter(dayjs(), 'day')) && 
                                        (!endDate || day.isSame(endDate, 'day') || day.isBefore(endDate, 'day'));
                  return !isWithinRange;
                }}
                sx={{
                  width: '100%',
                  '& .MuiPickersCalendarHeader-root': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 16px',
                    marginTop: '8px',
                  },
                  '& .MuiPickersCalendarHeader-label': {
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                  },
                  '& .MuiDayCalendar-weekDayLabel': {
                    fontSize: '1rem',
                    width: '58px !important',
                    color: 'text.secondary',
                    marginRight: '13px',
                  },
                  '& .MuiPickersDay-root': {
                    fontSize: '1rem',
                    width: '66px !important',
                    height: '49px !important',
                    margin: '2px',
                    paddingBottom: '10px',
                    marginBottom: '-4px',
                  },
                  '& .MuiDayCalendar-weekContainer': {
                    margin: '4px 0',
                  },
                }}
              />
            </LocalizationProvider>
            {/* <Typography sx={{ mt: 2 }}>Selected Discount: {discount}%</Typography> */}
            <Button
              variant="contained"
              onClick={handleNextStep}
              disabled={!selectedDate}
              sx={{
                width: '100%',
                height: '50px',
                marginTop: '16px',
                backgroundColor: '#1E61FE',
                borderRadius: '10px',
              }}
            >
              Accept Offer
              <EditIcon style={{ marginLeft: '8px' }} />
            </Button>
          </Box>
        );
      case 1:
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* <Typography variant="h5" sx={{ mb: 2 }}>Confirm New Deal Terms</Typography> */}
            <Stack direction="column" spacing={2} sx={{ flex: 1 }}>
              <Stack direction="row" spacing={3}>
                <Paper elevation={0} sx={{ bgcolor: '#f5f5f5', p: 2 }}>
                  <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                    <Typography variant="body2" fontWeight="bold">Original Invoice</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: '#1f2937', px: 1, py: 0.5, borderRadius: 1 }}>
                      <Typography variant="caption" sx={{ color: 'white', mr: 1 }}>Invoice #</Typography>
                      <Typography variant="caption" sx={{ color: 'white', fontWeight: 'bold' }}>{bill.source_invoice_number}</Typography>
                      <LaunchIcon sx={{ color: 'white', fontSize: 14, ml: 1 }} />
                    </Box>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" mt={2}>
                    <Typography variant="body2">Total</Typography>
                    <Typography variant="body1" fontWeight="bold">${bill.total_amount.toFixed(2)}</Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2">Due</Typography>
                    <Typography variant="body1" fontWeight="bold">{bill.due_date.toLocaleString().toString().substring(0, 10)}</Typography>
                  </Stack>
                </Paper>
                <div style={{ width: '380px', bgcolor: 'gray' }}>

                  <Typography variant="h6" fontWeight="bold" mt={2}>The Offer</Typography>
                  <Typography variant="body2">✓ Give only 0.06% discount for each advance payment day</Typography>
                  <Typography variant="body2">✓ It's a liquidation event that requires no 3rd party</Typography>
                  <Typography variant="body2">✓ Zero-Risk of late payment</Typography>
                </div>
              </Stack>
              
              
              
              <Typography variant="h6" fontWeight="bold" mt={2}>New Terms</Typography>
              <Stack direction="row" spacing={2} mt={2}>
                <Paper elevation={0} sx={{ 
                  background: `url(${EarlierPaymentBcg}) no-repeat center center`,
                  backgroundSize: 'cover',
                  p: 2,
                  flex: 2,
                  color: 'white' 
                }}>
                  <Typography variant="body2">You get your payment</Typography>
                  <Typography variant="h4" fontWeight="bold">{Math.floor(dayjs('2024-10-08').diff(selectedDate, 'day'))} days earlier</Typography>
                </Paper>
                <Paper elevation={0} sx={{ bgcolor: '#f5f5f5', p: 2, flex: 1, alignItems: 'center' }}>
                  <Typography variant="body2">Discount</Typography>
                  <Typography variant="h6" fontWeight="bold">{discount}%</Typography>
                </Paper>
              </Stack>
        
              <Stack direction="row" spacing={2} mt={2}>
                <Paper elevation={0} sx={{ bgcolor: '#f5f5f5', p: 2, flex: 1 }}>
                  <Typography variant="body2">New Total</Typography>
                  <Typography variant="h6" fontWeight="bold">${(bill.total_amount - (bill.total_amount * discount / 100)).toFixed(2)}</Typography>
                </Paper>
                <Paper elevation={0} sx={{ bgcolor: '#f5f5f5', p: 2, flex: 1 }}>
                  <Typography variant="body2">New Due Date</Typography>
                  <Typography variant="h6" fontWeight="bold">{selectedDate?.format('YYYY-MM-DD')}</Typography>
                </Paper>
              </Stack>
            </Stack>
            {/* <Typography variant="h6" sx={{ mb: 2 }}>Invoice #17662</Typography>
            <Stack direction="row" spacing={3}>
              <Typography>Billed on:</Typography>
              <Typography> 1 September 2024</Typography>
            </Stack>
            <Stack direction="row" spacing={3}>
              <Typography>Terms:</Typography>
              <Typography> On-Recipt</Typography>
            </Stack>
            <Stack direction="row" spacing={3}>
              <Typography>Original due date:</Typography>
              <Typography> 8 October 2024</Typography>
            </Stack>
            <Typography>Date: {selectedDate?.format('YYYY-MM-DD')}</Typography>
            <Typography>Discount: {discount}%</Typography> */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
              <Button
                variant="outlined"
                onClick={handleBackStep}
                sx={{
                  width: '48%',
                  height: '50px',
                  borderColor: '#1E61FE',
                  color: '#1E61FE',
                  borderRadius: '10px',
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                onClick={handleNextStep}
                sx={{
                  width: '48%',
                  height: '50px',
                  backgroundColor: '#1E61FE',
                  borderRadius: '10px',
                }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        );
      case 2:
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h4" sx={{ mb: 2 }}>Thank You!</Typography>
            <Typography>New payment terms have been confirmed.</Typography>
            <Typography color="#1E61FE">Click here if you would like to hear of future offers.</Typography>
            <Button
              variant="outlined"
              onClick={handleBackStep}
              sx={{
                width: '100%',
                height: '50px',
                marginTop: '16px',
                borderColor: '#1E61FE',
                color: '#1E61FE',
                borderRadius: '10px',
              }}
            >
              Back to Confirmation
            </Button>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: '825px', height: '600px', display: 'flex', flexDirection: 'column', margin: '10px', backgroundColor: '#FFF' }}>
      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 1 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Fade in={true} timeout={500}>
          <Box>{renderStepContent(activeStep)}</Box>
        </Fade>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 2 }}>
        <PoweredByFormula />
        {/* <button onClick={handleCreateCreditNote} disabled={isLoading}>
        {isLoading ? 'Creating...' : 'Create Credit Note'}
        </button>
        {creditNoteId && <p>Credit Note ID: {creditNoteId}</p>} */}
      </Box>
    </Box>
  );
};

export const EditIcon = (props) => (
<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="Icons">
    <path id="Vector 1 (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M15.2317 4.82609L15.2317 10.7544L13.9817 10.7544L13.9817 6.95997L6.20984 14.7319L5.32595 13.848L13.0978 6.07609L9.30343 6.07609L9.30343 4.82609L15.2317 4.82609Z" fill="white"/>
  </g>
</svg>
);

export const PoweredByFormula = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="160" height="16" viewBox="0 0 160 16" fill="none">
  <path d="M82.5292 15.5728C82.2754 15.5728 82.0443 15.5519 81.836 15.5103C81.6277 15.4724 81.4724 15.4307 81.3701 15.3853L81.7792 13.9932C82.0898 14.0765 82.3663 14.1125 82.6087 14.1012C82.8512 14.0898 83.0652 13.9989 83.2508 13.8284C83.4402 13.658 83.6068 13.3796 83.7508 12.9932L83.961 12.4137L80.7678 3.57275H82.586L84.7962 10.3455H84.8871L87.0974 3.57275H88.9212L85.3246 13.4648C85.158 13.9193 84.9459 14.3038 84.6883 14.6182C84.4307 14.9364 84.1239 15.175 83.7678 15.3341C83.4118 15.4932 82.9989 15.5728 82.5292 15.5728Z" fill="#A1A1A1"/>
  <path d="M71.8088 12.2999V0.663574H73.5077V4.98744H73.61C73.7085 4.80562 73.8505 4.59539 74.0361 4.35676C74.2217 4.11812 74.4793 3.90979 74.8088 3.73176C75.1384 3.54994 75.574 3.45903 76.1157 3.45903C76.8202 3.45903 77.449 3.63706 78.002 3.99312C78.555 4.34918 78.9888 4.86244 79.3032 5.53289C79.6213 6.20335 79.7804 7.01017 79.7804 7.95335C79.7804 8.89653 79.6232 9.70524 79.3088 10.3795C78.9944 11.0499 78.5626 11.567 78.0134 11.9306C77.4641 12.2905 76.8372 12.4704 76.1327 12.4704C75.6024 12.4704 75.1687 12.3814 74.8316 12.2033C74.4982 12.0253 74.2369 11.817 74.0475 11.5783C73.8581 11.3397 73.7122 11.1276 73.61 10.942H73.4679V12.2999H71.8088ZM73.4736 7.9363C73.4736 8.54994 73.5626 9.08782 73.7407 9.54994C73.9187 10.0121 74.1763 10.3738 74.5134 10.6352C74.8505 10.8927 75.2634 11.0215 75.752 11.0215C76.2596 11.0215 76.6838 10.8871 77.0247 10.6181C77.3657 10.3454 77.6232 9.97607 77.7975 9.51017C77.9755 9.04426 78.0645 8.51964 78.0645 7.9363C78.0645 7.36054 77.9774 6.8435 77.8032 6.38517C77.6327 5.92683 77.3751 5.56509 77.0304 5.29994C76.6895 5.03479 76.2634 4.90221 75.752 4.90221C75.2596 4.90221 74.8429 5.0291 74.502 5.28289C74.1649 5.53668 73.9092 5.89085 73.735 6.34539C73.5607 6.79994 73.4736 7.33024 73.4736 7.9363Z" fill="#A1A1A1"/>
  <path d="M60.5615 12.4704C59.857 12.4704 59.2282 12.2905 58.6752 11.9306C58.1259 11.567 57.6941 11.0499 57.3797 10.3795C57.0691 9.70524 56.9138 8.89653 56.9138 7.95335C56.9138 7.01017 57.071 6.20335 57.3854 5.53289C57.7036 4.86244 58.1392 4.34918 58.6922 3.99312C59.2453 3.63706 59.8722 3.45903 60.5729 3.45903C61.1146 3.45903 61.5502 3.54994 61.8797 3.73176C62.2131 3.90979 62.4706 4.11812 62.6525 4.35676C62.8381 4.59539 62.982 4.80562 63.0843 4.98744H63.1865V0.663574H64.8854V12.2999H63.2263V10.942H63.0843C62.982 11.1276 62.8343 11.3397 62.6411 11.5783C62.4517 11.817 62.1903 12.0253 61.857 12.2033C61.5237 12.3814 61.0918 12.4704 60.5615 12.4704ZM60.9365 11.0215C61.4252 11.0215 61.8381 10.8927 62.1752 10.6352C62.5161 10.3738 62.7737 10.0121 62.9479 9.54994C63.1259 9.08782 63.215 8.54994 63.215 7.9363C63.215 7.33024 63.1278 6.79994 62.9536 6.34539C62.7793 5.89085 62.5237 5.53668 62.1865 5.28289C61.8494 5.0291 61.4328 4.90221 60.9365 4.90221C60.4252 4.90221 59.999 5.03479 59.6581 5.29994C59.3172 5.56509 59.0597 5.92683 58.8854 6.38517C58.715 6.8435 58.6297 7.36054 58.6297 7.9363C58.6297 8.51964 58.7168 9.04426 58.8911 9.51017C59.0653 9.97607 59.3229 10.3454 59.6638 10.6181C60.0085 10.8871 60.4328 11.0215 60.9365 11.0215Z" fill="#A1A1A1"/>
  <path d="M51.6012 12.476C50.7413 12.476 50.0008 12.2923 49.3796 11.9249C48.7621 11.5537 48.2849 11.0328 47.9478 10.3624C47.6144 9.68815 47.4478 8.89838 47.4478 7.99308C47.4478 7.09914 47.6144 6.31126 47.9478 5.62944C48.2849 4.94762 48.7546 4.41542 49.3568 4.03285C49.9629 3.65027 50.6712 3.45898 51.4818 3.45898C51.9743 3.45898 52.4515 3.54042 52.9137 3.7033C53.3758 3.86618 53.7906 4.12186 54.158 4.47035C54.5254 4.81883 54.8152 5.27148 55.0273 5.8283C55.2394 6.38133 55.3455 7.05368 55.3455 7.84535V8.44762H48.408V7.17489H53.6807C53.6807 6.72792 53.5898 6.33209 53.408 5.98739C53.2262 5.63891 52.9705 5.36429 52.6409 5.16353C52.3152 4.96277 51.9326 4.86239 51.4932 4.86239C51.0159 4.86239 50.5993 4.97982 50.2432 5.21467C49.8909 5.44573 49.6182 5.74876 49.425 6.12376C49.2356 6.49497 49.1409 6.89838 49.1409 7.33398V8.3283C49.1409 8.91164 49.2432 9.40785 49.4478 9.81694C49.6561 10.226 49.9459 10.5385 50.3171 10.7544C50.6883 10.9666 51.122 11.0726 51.6182 11.0726C51.9402 11.0726 52.2337 11.0272 52.4989 10.9363C52.764 10.8416 52.9932 10.7014 53.1864 10.5158C53.3796 10.3302 53.5273 10.101 53.6296 9.8283L55.2375 10.1181C55.1087 10.5916 54.8777 11.0063 54.5443 11.3624C54.2148 11.7147 53.8 11.9893 53.3 12.1863C52.8038 12.3794 52.2375 12.476 51.6012 12.476Z" fill="#A1A1A1"/>
  <path d="M41.9478 12.2998V3.57258H43.5898V4.95894H43.6807C43.8398 4.48924 44.1201 4.11992 44.5216 3.85099C44.9269 3.57826 45.3853 3.44189 45.8966 3.44189C46.0027 3.44189 46.1277 3.44568 46.2716 3.45326C46.4193 3.46083 46.5349 3.4703 46.6182 3.48167V5.10667C46.55 5.08773 46.4288 5.06689 46.2546 5.04417C46.0803 5.01765 45.9061 5.00439 45.7318 5.00439C45.3303 5.00439 44.9724 5.08962 44.658 5.26008C44.3474 5.42674 44.1012 5.6597 43.9193 5.95894C43.7375 6.25439 43.6466 6.59152 43.6466 6.9703V12.2998H41.9478Z" fill="#A1A1A1"/>
  <path d="M36.2545 12.476C35.3946 12.476 34.6541 12.2923 34.0329 11.9249C33.4155 11.5537 32.9382 11.0328 32.6011 10.3624C32.2677 9.68815 32.1011 8.89838 32.1011 7.99308C32.1011 7.09914 32.2677 6.31126 32.6011 5.62944C32.9382 4.94762 33.4079 4.41542 34.0102 4.03285C34.6162 3.65027 35.3246 3.45898 36.1352 3.45898C36.6276 3.45898 37.1049 3.54042 37.567 3.7033C38.0291 3.86618 38.4439 4.12186 38.8113 4.47035C39.1787 4.81883 39.4685 5.27148 39.6806 5.8283C39.8927 6.38133 39.9988 7.05368 39.9988 7.84535V8.44762H33.0613V7.17489H38.334C38.334 6.72792 38.2431 6.33209 38.0613 5.98739C37.8795 5.63891 37.6238 5.36429 37.2943 5.16353C36.9685 4.96277 36.5859 4.86239 36.1465 4.86239C35.6693 4.86239 35.2526 4.97982 34.8965 5.21467C34.5443 5.44573 34.2715 5.74876 34.0783 6.12376C33.889 6.49497 33.7943 6.89838 33.7943 7.33398V8.3283C33.7943 8.91164 33.8965 9.40785 34.1011 9.81694C34.3094 10.226 34.5992 10.5385 34.9704 10.7544C35.3416 10.9666 35.7753 11.0726 36.2715 11.0726C36.5935 11.0726 36.8871 11.0272 37.1522 10.9363C37.4174 10.8416 37.6465 10.7014 37.8397 10.5158C38.0329 10.3302 38.1806 10.101 38.2829 9.8283L39.8908 10.1181C39.7621 10.5916 39.531 11.0063 39.1977 11.3624C38.8681 11.7147 38.4533 11.9893 37.9533 12.1863C37.4571 12.3794 36.8908 12.476 36.2545 12.476Z" fill="#A1A1A1"/>
  <path d="M21.3836 12.3L18.8154 3.57275H20.5711L22.2813 9.98184H22.3666L24.0825 3.57275H25.8382L27.5427 9.95344H27.6279L29.3268 3.57275H31.0825L28.52 12.3H26.787L25.0143 5.99889H24.8836L23.1109 12.3H21.3836Z" fill="#A1A1A1"/>
  <path d="M13.7288 12.476C12.9106 12.476 12.1966 12.2885 11.5868 11.9135C10.9769 11.5385 10.5034 11.0139 10.1663 10.3397C9.82921 9.66542 9.66064 8.87754 9.66064 7.97603C9.66064 7.07073 9.82921 6.27906 10.1663 5.60103C10.5034 4.923 10.9769 4.39648 11.5868 4.02148C12.1966 3.64648 12.9106 3.45898 13.7288 3.45898C14.547 3.45898 15.261 3.64648 15.8709 4.02148C16.4807 4.39648 16.9542 4.923 17.2913 5.60103C17.6284 6.27906 17.797 7.07073 17.797 7.97603C17.797 8.87754 17.6284 9.66542 17.2913 10.3397C16.9542 11.0139 16.4807 11.5385 15.8709 11.9135C15.261 12.2885 14.547 12.476 13.7288 12.476ZM13.7345 11.0499C14.2648 11.0499 14.7042 10.9097 15.0527 10.6294C15.4012 10.3491 15.6588 9.97603 15.8254 9.51012C15.9959 9.04421 16.0811 8.53095 16.0811 7.97035C16.0811 7.41353 15.9959 6.90217 15.8254 6.43626C15.6588 5.96656 15.4012 5.58967 15.0527 5.30558C14.7042 5.02148 14.2648 4.87944 13.7345 4.87944C13.2004 4.87944 12.7572 5.02148 12.405 5.30558C12.0565 5.58967 11.797 5.96656 11.6266 6.43626C11.4599 6.90217 11.3766 7.41353 11.3766 7.97035C11.3766 8.53095 11.4599 9.04421 11.6266 9.51012C11.797 9.97603 12.0565 10.3491 12.405 10.6294C12.7572 10.9097 13.2004 11.0499 13.7345 11.0499Z" fill="#A1A1A1"/>
  <path d="M0 12.2999V0.663574H4.14773C5.05303 0.663574 5.80303 0.828347 6.39773 1.15789C6.99242 1.48744 7.4375 1.9382 7.73295 2.51017C8.02841 3.07835 8.17614 3.7185 8.17614 4.43062C8.17614 5.14653 8.02652 5.79047 7.72727 6.36244C7.43182 6.93062 6.98485 7.38138 6.38636 7.71471C5.79167 8.04426 5.04356 8.20903 4.14205 8.20903H1.28977V6.72039H3.98295C4.55492 6.72039 5.01894 6.62191 5.375 6.42494C5.73106 6.22418 5.99242 5.95145 6.15909 5.60676C6.32576 5.26206 6.40909 4.87001 6.40909 4.43062C6.40909 3.99123 6.32576 3.60107 6.15909 3.26017C5.99242 2.91926 5.72917 2.65221 5.36932 2.45903C5.01326 2.26585 4.54356 2.16926 3.96023 2.16926H1.75568V12.2999H0Z" fill="#A1A1A1"/>
  <path d="M153.239 13.566C152.58 13.566 151.985 13.4519 151.453 13.2239C150.921 12.9833 150.497 12.6413 150.18 12.198C149.876 11.7546 149.724 11.2099 149.724 10.5639C149.724 9.93062 149.876 9.39862 150.18 8.96795C150.497 8.53728 150.927 8.21428 151.472 7.99895C152.017 7.77095 152.637 7.65695 153.334 7.65695H156.051V7.08695C156.051 6.59295 155.899 6.19395 155.595 5.88995C155.291 5.57328 154.816 5.41495 154.17 5.41495C153.537 5.41495 153.055 5.56695 152.726 5.87095C152.409 6.16228 152.2 6.54228 152.099 7.01095L150.085 6.34595C150.237 5.85195 150.478 5.40228 150.807 4.99695C151.149 4.59162 151.599 4.26862 152.156 4.02795C152.713 3.77462 153.397 3.64795 154.208 3.64795C155.437 3.64795 156.399 3.95828 157.096 4.57895C157.805 5.18695 158.16 6.07362 158.16 7.23895V10.9249C158.16 11.3049 158.337 11.4949 158.692 11.4949H159.49V13.2999H157.951C157.495 13.2999 157.121 13.1859 156.83 12.9579C156.539 12.7299 156.393 12.4196 156.393 12.0269V11.9889H156.07C155.994 12.1663 155.861 12.3816 155.671 12.6349C155.481 12.8883 155.19 13.11 154.797 13.2999C154.417 13.4773 153.898 13.566 153.239 13.566ZM153.6 11.7799C154.322 11.7799 154.911 11.5773 155.367 11.1719C155.823 10.7539 156.051 10.1966 156.051 9.49995V9.30995H153.467C152.998 9.30995 152.618 9.41128 152.327 9.61395C152.036 9.81662 151.89 10.1143 151.89 10.5069C151.89 10.8869 152.042 11.1973 152.346 11.438C152.65 11.6659 153.068 11.7799 153.6 11.7799Z" fill="#A1A1A1"/>
  <path d="M146.138 13.3V0H148.323V13.3H146.138Z" fill="#A1A1A1"/>
  <path d="M138.799 13.4516C138.077 13.4516 137.438 13.2932 136.88 12.9766C136.323 12.6472 135.892 12.1912 135.588 11.6086C135.284 11.0132 135.132 10.3229 135.132 9.53757V3.91357H137.298V9.36657C137.298 10.1266 137.482 10.6902 137.849 11.0576C138.229 11.4249 138.761 11.6086 139.445 11.6086C140.218 11.6086 140.826 11.3552 141.269 10.8486C141.725 10.3419 141.953 9.61357 141.953 8.66357V3.91357H144.119V13.2996H141.991V11.9886H141.668C141.504 12.3306 141.2 12.6599 140.756 12.9766C140.326 13.2932 139.673 13.4516 138.799 13.4516Z" fill="#A1A1A1"/>
  <path d="M119.863 13.2997V3.91368H122.01V4.95868H122.333C122.497 4.64201 122.77 4.36334 123.15 4.12268C123.542 3.86934 124.062 3.74268 124.708 3.74268C125.392 3.74268 125.943 3.88201 126.361 4.16068C126.779 4.43934 127.095 4.79401 127.311 5.22468H127.615C127.83 4.79401 128.14 4.43934 128.546 4.16068C128.951 3.88201 129.527 3.74268 130.275 3.74268C130.857 3.74268 131.383 3.86934 131.852 4.12268C132.333 4.37601 132.713 4.74968 132.992 5.24368C133.283 5.73768 133.429 6.35201 133.429 7.08668V13.2997H131.244V7.23868C131.244 6.69401 131.098 6.28234 130.807 6.00368C130.515 5.71234 130.11 5.56668 129.591 5.56668C129.021 5.56668 128.565 5.75668 128.223 6.13668C127.893 6.50401 127.729 7.03601 127.729 7.73268V13.2997H125.563V7.23868C125.563 6.69401 125.417 6.28234 125.126 6.00368C124.834 5.71234 124.429 5.56668 123.91 5.56668C123.327 5.56668 122.871 5.75668 122.542 6.13668C122.212 6.50401 122.048 7.03601 122.048 7.73268V13.2997H119.863Z" fill="#A1A1A1"/>
  <path d="M113.201 13.3V3.91398H115.348V4.99698H115.671C115.81 4.60431 116.045 4.31931 116.374 4.14198C116.703 3.96464 117.096 3.87598 117.552 3.87598H118.692V5.81398H117.514C116.881 5.81398 116.368 5.98498 115.975 6.32698C115.582 6.65631 115.386 7.16931 115.386 7.86598V13.3H113.201Z" fill="#A1A1A1"/>
  <path d="M106.755 13.566C105.817 13.566 104.975 13.3759 104.228 12.9959C103.493 12.6033 102.91 12.0523 102.48 11.3429C102.062 10.6209 101.853 9.75962 101.853 8.75895V8.45495C101.853 7.45428 102.062 6.59295 102.48 5.87095C102.91 5.14895 103.493 4.59795 104.228 4.21795C104.975 3.83795 105.817 3.64795 106.755 3.64795C107.692 3.64795 108.528 3.83795 109.263 4.21795C109.997 4.59795 110.574 5.14895 110.992 5.87095C111.422 6.59295 111.638 7.45428 111.638 8.45495V8.75895C111.638 9.75962 111.422 10.6209 110.992 11.3429C110.574 12.0523 109.997 12.6033 109.263 12.9959C108.528 13.3759 107.692 13.566 106.755 13.566ZM106.755 11.6279C107.553 11.6279 108.205 11.3746 108.712 10.8679C109.218 10.3486 109.472 9.62662 109.472 8.70195V8.51195C109.472 7.58728 109.218 6.87162 108.712 6.36495C108.205 5.84562 107.553 5.58595 106.755 5.58595C105.957 5.58595 105.304 5.84562 104.798 6.36495C104.291 6.87162 104.038 7.58728 104.038 8.51195V8.70195C104.038 9.62662 104.291 10.3486 104.798 10.8679C105.304 11.3746 105.957 11.6279 106.755 11.6279Z" fill="#A1A1A1"/>
  <path d="M96.7426 13.3V5.719H94.2346V3.914H96.7426V2.014C96.7426 1.39333 96.92 0.905668 97.2746 0.551001C97.642 0.183667 98.1233 0 98.7186 0H101.151V1.805H99.4406C99.086 1.805 98.9086 1.995 98.9086 2.375V3.914H101.493V5.719H98.9086V13.3H96.7426Z" fill="#A1A1A1"/>
</svg>
  );


export default App;
